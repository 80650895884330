import React from "react"
import "../styles/global.css";
import { Link } from "gatsby"
import { slide as Menu } from 'react-burger-menu'
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
    return <div class="structure">
        <Helmet>
            <meta charSet="utf-8" />
            <title>D&G Plumbing and Gas | All Plumbing and Gas | (08) 8387 3833</title>
            <link rel="canonical" href="http://www.dgplumbing.com.au" />
            <meta name="description" content="D&G Plumbing services all metropolitan Adelaide, the Adelaide Hills, southern Adelaide, and the Fleurieu Region." />
        </Helmet>
        <div class="header">
            <div class="navbar-brand"><Link to="/">
                <img alt="D&amp;G Plumbing and Gas" src="/Images/DG horizontal RGB.webp" />
            </Link>
            </div>
            <ul class="menu-buttons">
                <li><Link to="/contact">Contact Us</Link></li>
                <li><a href="tel:0883873833"><span class="glyphicon glyphicon-earphone"></span> 08 8387 3833</a></li>
            </ul>
            <Menu right noOverlay>
                <h2>Menu</h2>
                <Link to="/contact">Contact Us</Link>
                <a href="tel:0883873833"><span class="glyphicon glyphicon-earphone"></span> 08 8387 3833</a>
            </Menu>
        </div>

        <div class="body-content">
            {children}
        </div>

        <footer>
            <div class="footer-container">
                <div class="footer-icons">
                    <a href="https://www.facebook.com/pages/DG-Plumbing/387136877993082" target="_blank" rel="noreferrer" title="Facebook">
                        <img src="/Images/facebook-logo.png" alt="D&amp;G Plumbing and Gas on Facebook" />
                    </a>
                </div>
                <div class="footer-icons" id="footer-logo">
                    <img style={{ height: "65px", marginTop: "10px" }} src="/Images/dandg_logo_small.png" alt="D&amp;G Plumbing and Gas" />
                </div>

                <div class="footer-icons">
                    <a href="mailto:admin@dgplumbing.com.au" title="Email Us"><img alt="envelope" style={{ marginTop: "10px" }} src="/Images/envelope.png" /></a>
                </div>
            </div>
            <br />
            <div class="business-info"><p>© 2020 - D&amp;G PLUMBING (SA) PTY LTD</p>
                <p>
                    <span>ABN: 47 169 826 288</span>
                    <span>Licence: PGE 262543</span>
                </p>
            </div>
        </footer>
    </div>
}